import { useQuery } from '@tanstack/react-query';
import { ActivityIndicator, View } from 'react-native';
import { Challenge, RedemptionsResponse, TransactionsResponse } from '../interfaces';
import { getChallengesV2, getUserRedemptions, getUserTransactions } from '../api';
import { HistoryCard } from './HistoryCard';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import DText from './DText';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import { TicketCreditCard } from './TicketCreditCard';
import { useContext, useState } from 'react';
import { WindowContext } from '../App';
import { TransactionHistoryCard } from './TransactionHistoryCard';

export function HistoryList() {
  const [challengesLimit, setChallengesLimit] = useState(20);
  const windowValues = useContext(WindowContext);
  const {
    isLoading: isLoadingChallengeHistory,
    isPreviousData: isLoadingMoreChallenges,
    isError: isChallengeHistoryError,
    error: challengeHistoryError,
    data: challengeHistory,
  } = useQuery<Challenge[], Error, Challenge[], (string | undefined)[]>({
    queryKey: [
      'ChallengeHistoryData',
      challengesLimit.toString(),
      'true',
      undefined,
      undefined,
      windowValues.accountId,
    ],
    queryFn: getChallengesV2(windowValues),
    keepPreviousData: true,
  });

  const {
    isLoading: isLoadingRedemptionHistory,
    isError: isRdemptionHistoryError,
    error: redemptionHistoryError,
    data: redemptionHistory,
  } = useQuery<RedemptionsResponse[], Error, RedemptionsResponse[], (string | undefined)[]>({
    queryKey: ['RedemptionHistoryData', windowValues.accountId],
    queryFn: getUserRedemptions(windowValues),
  });

  const {
    isLoading: isLoadingTransactionHistory,
    isError: isTransactionHistoryError,
    error: transactionHistoryError,
    data: transactionHistory,
  } = useQuery<TransactionsResponse[], Error, TransactionsResponse[], (string | undefined)[]>({
    queryKey: ['TransactionHistoryData', windowValues.accountId],
    queryFn: getUserTransactions(windowValues),
  });

  if (isLoadingChallengeHistory || isLoadingRedemptionHistory || isLoadingTransactionHistory) {
    return (
      <View style={{ marginTop: 16 }}>
        <ActivityIndicator />
      </View>
    );
  }

  if (isChallengeHistoryError || isRdemptionHistoryError || isTransactionHistoryError) {
    return (
      <View>
        <DText fontSize={Fonts.font16} color={Colors.white}>
          {challengeHistoryError?.message ||
            redemptionHistoryError?.message ||
            transactionHistoryError?.message ||
            'Unknown error'}
        </DText>
      </View>
    );
  }

  const challengesThatHaveBeenPlayed = challengeHistory.filter((challenge) => {
    const hasPlayedChallenge = challenge.games?.some((game) => {
      return game.plays.length > 0;
    });
    if (hasPlayedChallenge) {
      return challenge;
    }
  });

  const wholeHistory: {
    createdAt: Date | string;
    startDate?: Date | string;
    id: number;
    description?: string;
  }[] = [...redemptionHistory, ...challengesThatHaveBeenPlayed, ...transactionHistory];
  const sortedHistory = wholeHistory.sort((a, b) => {
    // Determine the date to use for sorting for both a and b
    const dateA = new Date(a.startDate || a.createdAt).getTime();
    const dateB = new Date(b.startDate || b.createdAt).getTime();
    // Sort by most recent date first
    return dateA === dateB ? a.id - b.id : (dateB || 0) - (dateA || 0);
  });
  const showLoadMoreButton = isLoadingMoreChallenges || challengesLimit <= challengeHistory.length;
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: 12,
        marginVertical: 4,
      }}
    >
      {sortedHistory.map((historyItem, index) => {
        if (historyItem) {
          if ('prizeId' in historyItem) {
            return <TicketCreditCard key={index} redemption={historyItem as RedemptionsResponse} />;
          }
          if ('description' in historyItem) {
            return (
              <TransactionHistoryCard
                key={index}
                transaction={historyItem as TransactionsResponse}
              />
            );
          }
          return <HistoryCard key={index} challenge={historyItem as Challenge} />;
        }
      })}
      {showLoadMoreButton && (
        <BiggerTouchableOpacity
          onPress={() => setChallengesLimit(challengesLimit + 10)}
          activeOpacity={0.5}
          style={{
            flexDirection: 'row',
            width: '33%',
            height: 36,
            padding: '6px 16px',
            borderRadius: 4,
            borderWidth: 1,
            borderColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          {isLoadingMoreChallenges ? (
            <ActivityIndicator />
          ) : (
            <DText fontWeight={600} fontSize={Fonts.font13} color={'white'}>
              {'Load More'}
            </DText>
          )}
        </BiggerTouchableOpacity>
      )}
    </View>
  );
}
